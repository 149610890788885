//
// Google font - Poppins
//

// @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;0,900;1,400;1,600;1,700;1,800;1,900&display=swap");

@font-face {
    font-family: 'Monetasans-Black';
    src: local('Monetasans-Black'), url(../../../../assets/fonts/Moneta-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Helvetica';
    src: local('Helvetica'), url(../../../../assets/fonts/HELVETICA.OTF) format('opentype');
}