//
// _helper.scss
//

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-36 {
  font-size: 36px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-48 {
  font-size: 48px !important;
}

.font-size-56 {
  font-size: 56px !important;
}

.font-size-64 {
  font-size: 64px !important;
}

.hero-font-title {
  font-size: 70px !important;
  font-weight: 900;

  @media (max-width: 991.98px) {
    font-size: 40px !important;

    @media (max-width: 600px) {
      font-size: 36px !important;

      @media (max-width: 375px) {
        font-size: 32px !important;
      }
    }
  }
}

.hero-font-subtitle {
  font-size: 28px !important;
  padding-left: 150px;
  padding-right: 150px;
  font-weight: 600;

  @media (max-width: 991.98px) {
    font-size: 20px !important;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 600px) {
      font-size: 18px !important;
    }
  }
}

.shop-item-title {
  font-size: 16px !important;
  @media (min-width: 991.98px) {
    font-size: 16px !important;
  }
}

.shop-item-subtitle {
  font-size: 15px !important;
  @media (min-width: 991.98px) {
    font-size: 15px !important;
  }
}

.shop-detail-features {
  @media (min-width: 1199.98px) {
    margin-left: -15%;
    margin-right: -15%;
    padding-left: 15%;
    padding-right: 15%;
  }
}

.shop-detail-alert {
  display: block;
  background: #FFEDE8;
  border: 2px solid #FFBFAB;
  border-radius: 16px;
  padding: 12px 16px;
}

.checkout-header {
  font-size: 20px;
  @media (max-width: 767.98px) {
    // font-size: 20px !important;
    display: none;
  }
}

.about-side-image {
  display: block;
  object-fit: scale-down;
  max-height: 450px;
  @media (max-width: 991px) {
    display: none;
  }
}

.about-top-image {
  display: none;
  @media (max-width: 991px) {
    display: block;
    max-height: 380px;
    object-fit: scale-down;

  }
}

.about-text {
  font-size: 20px;
  @media (max-width: 375px) {
    font-size: 18px;
  }
}

.home-hero-image {
  max-width: 100%;
  height: auto;

  @media (max-width: 600px) {
    width: auto;
    height: 375px;
    object-fit: cover;
  }
}

.home-writeup-title {
  line-height: 125%;
  @media (max-width: 900px) {
    font-size: 40px !important;
    padding-left: 32px;
    padding-right: 32px;
    
    @media (max-width: 600px) {
      font-size: 32px !important;
    }
  }
}

.home-writeup-subtitle {
  @media (max-width: 900px) {
    font-size: 20px !important;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 600px) {
      font-size: 18px !important;
    }
  }
}

.home-alternate-image {
  @media (min-width: 992px) {
    object-fit: cover !important;
    border-radius: 16px !important;
    aspect-ratio: 450/331 !important;
  }
}

.home-alternate-text {
  @media (max-width: 992px) {
    text-align: center;
  }
}

.home-howitworks-title {
  font-size: 48px;
  font-weight: $font-weight-black;
  @media (max-width: 992px) {
    font-size: 40px;

    @media (max-width: 600px) {
      font-size: 32px;
    }
  }
}

.home-howitworks-cards {
  @media (min-width: 992px) {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

.selection-box {
  padding: 12px 18px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  margin: 0px 25px;
}

.selection-item {
  padding: 8px 16px;
}

.profile-textbox {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #8F9BB3;

  background: #F3F4F8;
  border: 2px solid #EBEBEB;
  border-radius: 16px;
  padding: 16px;
  margin-right: 32px;

  @media (max-width: 767px) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.address-box {
  border: 2px solid #EBEBEB;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  padding: 16px;
  margin-right: 32px;

  @media (max-width: 767px) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.address-text {
  font-size: 14px;
  line-height: 19px;
  padding-left: 16px;
}

.edit-address {
  border: 2px solid #F3F4F8;
  border-radius: 16px;
  padding: 16px 10px 16px 16px;
  margin-bottom: 0.75rem;
  margin-right: 32px;

  @media (max-width: 767px) {
    margin-right: 16px;
  }
}

.dashboard-responsive {
  @media (max-width: 767px) {
    margin-left: 16px;
  }
}

.order-details-card {
  border-radius: 16px;
  padding: 16px 16px 0px;
  margin-left: -12px;
  margin-right: 24px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.075);

  @media (max-width: 767px) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.image-outline-primary {
  border: 2px solid $primary;
}

.button-outline-c4c4c4 {
  border-color: #c4c4c4;
}

.button-outline-ced4da {
  border-color: #ced4da;
}

.button-outline-f0f0f0 {
  border-color: #f0f0f0;
}

.button-outline-f3f4f8 {
  border-color: #f3f4f8;
}

.height-56 {
  height: 56px;
}

.border-none {
  border: none;
}

.border-width-2 {
  border-width: 2px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-16 {
  border-radius: 16px;
}

.color-8F9BB3 {
  color: #8f9bb3;
}

.color-62697E {
  color: #62697e;
}

.bg-F3F4F8 {
  background-color: #f3f4f8;
}

.bg-360623 {
  background-color: #360623;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}
// Font weight help class

.fw-medium {
  font-weight: $fw-medium;
}

.font-weight-normal {
  font-weight: $font-weight-normal;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.font-weight-bold {
  font-weight: $font-weight-bolder;
}

.font-weight-extrabold {
  font-weight: $font-weight-extrabold;
}

.font-weight-black {
  font-weight: $font-weight-black !important;
}

// Social

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 4px);
  display: block;
  border: 2px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
  transition: all 0.4s;
  &:hover {
    color: $gray-600;
    background-color: $gray-200;
  }
}

.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

// media

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

// alert

.alert-dismissible {
  .btn-close {
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
    background: transparent escape-svg($btn-close-bg-dark) center /
      $btn-close-width auto no-repeat;
  }
}

.font-monetasans-black {
  font-family: "Monetasans-Black" sans-serif;
}

.zoom-in-out-box {
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.z-1001 {
  z-index: 1001 !important;
}

.text-color-919695 {
  color: #919695;
}