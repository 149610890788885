
//
// Range slider
//

.irs {
  font-family: $font-family-base;
}

.irs--square{
  .irs-bar, .irs-to, .irs-from, .irs-single {
    background: $primary !important;
    font-size: 11px;
  }
  .irs-to, .irs-from, .irs-single{
    &:before{
      border-top-color: $primary;
    }
  }

  .irs-line{
    background: $gray-300;
    border-color: $gray-300;
  }
  .irs-grid-text{
    font-size: 11px;
    color: $gray-400;
  }
  .irs-min, .irs-max{
    color: $gray-400;
    background: $gray-300;
    font-size: 11px;
  }

  .irs-handle{
    border: 2px solid $primary;
    width: 12px;
    height: 12px;
    top: 26px;
    background-color: $card-bg !important;
  }
}

.rangeslider-vertical {
  background-color: #FFFFFF;
  border-radius: 5px;
  width: 6px;
}

.rangeslider-vertical .rangeslider__fill {
  border-radius: 5px;
}

.rangeslider-vertical .rangeslider__handle {
  /* width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none; */
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(0%, 0%, 0);
}

.rangeslider-vertical .rangeslider__handle:after {
  content: ' ';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}

.rangeslider-horizontal {
  height: 6px;
}