//
// accordion.scss
//

.custom-accordion {
  .accordion-list {
    display: flex;
    border-radius: 7px;
    background-color: $gray-300;
    padding: 12px 20px;
    color: $body-color;
    font-weight: $font-weight-semibold;
    align-items: center;
    justify-content: space-between;
    &.collapsed {
      i.accor-plus-icon {
        &:before {
          content: "\F0415";
        }
      }
    }

    .accor-plus-icon {
      display: inline-block;
      font-size: 16px;
      height: 24px;
      width: 24px;
      line-height: 22px;
      background-color: $card-bg;
      text-align: center;
      border-radius: 50%;
    }
  }

  a {
    &.collapsed {
      i.accor-down-icon {
        &:before {
          content: "\F0140";
        }
      }
    }
  }

  .card-body {
    color: $text-muted;
  }
}

.accordion-button {
  background-color: $white;
  border-radius: 16px;
  border: none;

  &:not(.collapsed) {
    color: $secondary;
    background-color: $white;
    box-shadow: none;
  }
}

.accordion-button.shop {
  font-size: 16px;
  font-weight: $font-weight-extrabold;
}

.accordion-button.faq {
  font-size: 15px;
  font-weight: $font-weight-bolder;
}

.accordion-body.faq {
  font-size: 15px;
  font-weight: $font-weight-bold;
  opacity: 0.7;
}

.accordion-button.howitworks {
  font-size: 15px;
  font-weight: $font-weight-black;
}

.accordion-body.howitworks {
  font-size: 15px;
  font-weight: $font-weight-bold;
  opacity: 0.7;
}

.accordion-item {
  background-color: $white;
  border-radius: 16px;
  border: none;

  &:first-of-type {
    @include border-top-radius(16px);

    .accordion-button {
      @include border-top-radius(16px);
    }
  }

  &:last-of-type {
    @include border-bottom-radius(16px);

    .accordion-button {
      &.collapsed {
        @include border-bottom-radius(16px);
      }
    }

    .accordion-collapse {
      @include border-bottom-radius(16px);
    }
  }
}
